import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";

import ImageBox from "../components/imageBox";
import ImageRoundBox from "../components/imageRoundBox";
import ImageLink, { getImageUrl } from "../components/imageLink";

import "./club.css";

import LocationIcon from "../assets/icons/location";
import ClockIcon from "../assets/icons/clock";
import ContactIcon from "../assets/icons/contact";
import ScheduleIcon from "../assets/icons/schedule";

import Loader from "../components/loader";
import { getClub } from "../api/club/clubManager";
import Footer from "../components/footer";
import InfoIcon from "../assets/icons/info";
import { formatOpenWeekdays } from "../utils/dateUtils";
import { formatPhoneNumber, isPhoneNumber, isValidHttpUrl, validateEmail } from "../utils/generalUtils";

function loadParagraphs(text) {
    var paragraphs = [];
    var parts = text.split(" ");
    for(var i = 0; i < parts.length; i++) {
        if(validateEmail(parts[i])) {
            paragraphs.push(<a href={"mailto:" + parts[i]}>{parts[i]}</a>);
        }else if(isValidHttpUrl(parts[i])) {
            paragraphs.push(<a target="_blank" href={parts[i]}>{parts[i].replace("https://", "").replace("http://", "")}</a>)
        }else if(isPhoneNumber(parts[i])) {
            paragraphs.push(<a target="_blank" href={"tel:" + parts[i]}>{formatPhoneNumber(parts[i])}</a>)
        }else {
            paragraphs.push(<>{parts[i]}</>);
        }
    }
    return paragraphs;
}

function ClubPage(props) {

    const navigate = useNavigate();

    const {id} = useParams("id");

    const [clubData, setClubData] = useState();

    useEffect(() => {
        if(clubData == undefined) {
            getClub(id, (data) => {
                setTimeout(() => {
                    setClubData(data);
                }, 600);
            });
        }
    });

    if(clubData == undefined) {
        return <Loader/>;
    }

    var location = "";
    if(clubData.street) {
        location += clubData.street + "\n";
    }
    if(clubData.city || clubData.zip) {
        location += clubData.city + " " + clubData.zip + "\n";
    }
    if(clubData.addressAddition) {
        location += clubData.addressAddition + "\n";
    }
    if(location.length > 0) {
        location = location.substring(0, location.length - 1);
    }

    
    const imageLinks = clubData.imageLinks.split(";");
    const imageAttributes = clubData.imageAttributes.split(";");

    return <>
        <div className="page" id="club-page">

<p className="grey navinfo"><a onClick={() => {
    navigate("/clubs");
    }}>Vereine</a> / <a onClick={() => {
    navigate("/clubs?search=:k:" + clubData.category);
}}>{clubData.category}</a> / {clubData.name}</p>

<div className="section">
    <div className="title-space">
        <ImageLink link={clubData.logo}/>
        <div className="titles">
            <h1 className="default-font">{clubData.title}</h1>
            <h2 className="default-font">{clubData.subtitle}</h2>
        </div>
    </div>
    <div className={"content" + (!clubData.imageLinks.includes(";") ? " noimages" : "")}>
    {
        imageLinks.map((e, i) => {
            if(e == "") return;
            return <ImageRoundBox alt={imageAttributes[i].split(",")[1]} fullscreen={imageAttributes[i].split(",")[0] == "true"} image={e}/>;
        })
    }
        <div className="box info-box">
            {clubData.news != null && clubData.news.length != 0 ? <div className="field">
                <InfoIcon/>
                <h3>Aktuelle Informationen</h3>
                {clubData.news.split("\n").map(r => {
                    return <p>{loadParagraphs(r).map(e => {
                        return <>{e} </>
                    })}</p>;
                })}
            </div> : <></>}
            {location != null && location.length != 0 ? <div className="field">
                <LocationIcon/>
                <h3>Adresse</h3>
                {location.split("\n").map(r => {
                    return <p>{loadParagraphs(r).map(e => {
                        return <>{e} </>
                    })}</p>;
                })}
            </div> : <></>}
            {clubData.open != null && clubData.open.length != 0 ? <div className="field">
                <ClockIcon/>
                <h3>Öffnungszeiten</h3>
                {clubData.open.startsWith("text") ? clubData.open.split("\n").map((r, i) => {
                    if(i == 0) return;
                    return <p>{r}</p>;
                }) : formatOpenWeekdays(clubData.open).split("\n").map(r => {
                    return <p>{r}</p>;
                })}
            </div> : <></>}
            {clubData.contact != null && clubData.contact.length != 0 ? <div className="field">
                <ContactIcon/>
                <h3>Kontakt</h3>
                {clubData.contact.split("\n").map(r => {
                    var paragraphs = loadParagraphs(r);
                    return <p>{paragraphs.map(e => {
                        return <>{e} </>;
                    })}</p>;
                })}
            </div> : <></>}
        </div>
        <div className="text">{clubData.content.split("\n").map(r => {
            return <p>{loadParagraphs(r).map(e => {
                    return <>{e} </>
                })}</p>;
        })}
        </div>
    </div>
</div>
</div>
<Footer/>
    </>;

}

export default ClubPage;