import React from "react";

export default function CloudTopArrow() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="37" height="25" viewBox="0 0 37 25">
    <g id="Gruppe_110" data-name="Gruppe 110" transform="translate(-1751.131 -2146)">
      <rect id="Rechteck_97" data-name="Rechteck 97" width="37" height="23" rx="11.5" transform="translate(1751.131 2146)" fill="#fff"/>
      <line id="Linie_18" data-name="Linie 18" y1="15" transform="translate(1769.131 2156)" fill="none" stroke="#2e2e2e" stroke-width="5"/>
      <path id="Pfad_136" data-name="Pfad 136" d="M-257.949,8703.395l-8.026-7.337-8.026,7.337" transform="translate(2035.146 -6542.427)" fill="none" stroke="#2e2e2e" stroke-width="5"/>
    </g>
  </svg>;  
}