import React, { useEffect, useState } from "react";

import "./header.css";

import logo from "../assets/img/logo.svg";
import { useNavigate } from "react-router-dom";

import SearchIcon from "../assets/icons/search";

function Header(props) {

    const names = [
        "Start",
        "Aktuelles",
        "Terminkalender",
        "Vereine",
        "Helferbörse"
    ]
    const links = [
        "/home",
        "/articles",
        "/schedule",
        "/clubs",
        "/aider"
    ]

    const [mobile, setMobile] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", checkForMobile);
        checkForMobile();
    });
    function checkForMobile() {
        if(window.innerWidth < 1170) {
            setMobile(true);
        }else {
            setMobile(false);
        }
    }

    return <header className={(mobile ? "mobile" : "") + (mobileOpen ? " open" : "")}>
        <div className="logo" onClick={() => {
            window.open("/home", "_self");
        }}>
            <img src={logo}/>
            <h1>BergAmLaimer<span>.info</span></h1>
        </div>
        
        
        <ul>
            {names.map((e, i) => {
                return <li className={props.active == i ? "active" : ""} onClick={() => {
                    window.open(links[i], "_self");
                }}><a>{e}</a><hr/></li>
            })}
        </ul>
        {mobile ? <>
            <div className="burger" onClick={() => {setMobileOpen(!mobileOpen);}}>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
            </div>
        </> : <></>}
        <div className="search" onClick={() => {
            window.open("/search", "_self");
        }}>
            <SearchIcon/>
        </div>
        {mobile ? 
            <p className="pagename">{names[props.active]}</p> : <></>}
    </header>;
}

export default Header;