import { sendRequest } from "./apiManager";

function addNewsletter(type, email, onFinish, onError) {
    sendRequest("newsletter/" + type + "/" + email, "POST", {}, (r) => {
        if(r.status == 201) {
            onFinish();
        }else {
            onError(r.message);
        }
    });

}

function logoutNewsletter(type, email, key, onFinish) {
    sendRequest("newsletter/" + type + "/" + email + "/" + key, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            window.open("/error/500", "_self");
        }
    });
}

export {addNewsletter, logoutNewsletter}