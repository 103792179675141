import React from "react";

import "./searchResult.css";

import testImg from "../assets/img/img1.webp";
import { formatDate } from "../api/apiManager";

import { getApiUrl } from "../api/apiManager";

function SearchResultBox(props) {
    
    
    var imgLink = getApiUrl() + "image/" + props.img;
    
    return <div className="box search-result" onClick={() => {
        window.open(props.link, "_self");
    }}>
        <div className="content">
            <div className="header">
                <a className="type">{props.type}</a>
                <a className="date">{formatDate(props.date)}</a>
            </div>
            <div className="text">
                <h1>{props.title}</h1>
                <p>{props.text}</p>
                
            </div>
        </div>
        <img src={imgLink} className="img"/>
    </div>;
}

export default SearchResultBox;