import React, { useEffect, useState } from "react";

import "./loader.css";

function Loader() {

    const [income, setIncome] = useState(true);

    useEffect(() => {
        if(income) {
            setTimeout(() => {
                setIncome(false);
            }, 1000);
        }
    })

    return <div className="loader-div">
        <span className={"loader" + (income ? " income" : "")}/>
    </div>;
}

export default Loader;