import { getNodeText } from "@testing-library/react";
import React, {useState, useEffect} from "react";

import { useLocation } from "react-router-dom";
import { addNewsletter } from "../api/newsletterManager";
import { getText } from "../api/textManager";
import CloudTopArrow from "../assets/icons/cloudtoparrow";
import EmailIcon from "../assets/icons/email";

import YoutubeIcon from "../assets/icons/youtube";

import "./footer.css";
import SearchButton from "./searchButton";

function Footer() {

    const [newsletterText, setNewsletterText] = useState("");
    
    const [absolute, setAbsolute] = useState(false);
    
    let location = useLocation();

    const [newsletterError, setNewsletterError] = useState("");
    const [newsletter, setNewsletter] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(update, [location]);

    useEffect(() => {
        update();
        setTimeout(update, 100);
    });

    useEffect(() => {
        window.addEventListener("resize", update);
    })

    function update() {
        var screenHeight = window.innerHeight;
        var pageHeight = document.body.scrollHeight + (absolute ? document.getElementsByTagName("footer")[0].offsetHeight : 0);
        if(screenHeight > pageHeight) {
            if(!absolute) {
                setAbsolute(true);
            }
        }else {
            if(absolute) setAbsolute(false);
        }

        if(newsletterText.length == 0) {
            getText("footer_newsletter", setNewsletterText);
        }

    }

    return <footer className={absolute == true ? "absolute" : ""}>
        <div className="newsletter">
        <h1>{newsletterText}</h1>
            <p className="newsletter-error">{newsletterError}</p>
            {newsletter ? <p className="newsletter-success">Danke! <span>{email}</span> wurde zum Newsletter hinzugefügt.</p> : <SearchButton onEnter={() => {
                addNewsletter("normal", email, () => {
                    setNewsletter(true);
                }, (r) => {
                    setNewsletterError(r);
                });
            }} onInput={(v) => {
                setEmail(v);
                setNewsletterError("");
            }} icon={<EmailIcon/>} name="Newsletter anmelden" placeholder="Email eingeben, mit Enter bestätigen"/>}
        </div>
        <div className="top">

            
            <p className="credit">Website umgesetzt von <a href="mailto:info@vueste.de">Vueste</a></p>
            <div className={"up" + (absolute ? " disabled" : "")} onClick={() => {

                window.scrollTo(0, 0);
            }}>
                nach oben
                <CloudTopArrow/>
            </div>
        </div>
        <div className="links">
            <span>BergamLaimer.info</span>
            <ul>
                <li onClick={() => {
                    window.open("/imprint", "_self");
                }}>Impressum</li>
                <li onClick={() => {
                    window.open("/privacy", "_self");
                }}>Datenschutz</li>
                <li onClick={() => {
                    window.open("/contact", "_self");
                }}>Kontakt</li>
            </ul>
        </div>
    </footer>;
}

export default Footer;