import { useLocation } from "react-router-dom";

const API_URL = "https://api.bergamlaimer.info/";

function sendRequest(path, verb, body, onresp, auth) {
    fetch(API_URL + path, {
        method: verb,
        body: Object.keys(body).length ? JSON.stringify(body) : undefined,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': auth
        },
    })
        .then((response) => response.json())
        .then((data) => {
            onresp(data);
        })
        .catch((err) => {
            console.log("An error occured while api request: " + err);
            if(window.location.href.includes("/error/503")) return;
            //window.open("/error/503", "_self");
        });
}

function getApiUrl() {
    return API_URL;
}

function formatDate(d) {
    var dateObj = new Date(d);
    return addZeroToNumberUnderTen(dateObj.getDate()) + "/" + addZeroToNumberUnderTen(dateObj.getMonth()+1) + "/" + dateObj.getFullYear();
}


function addZeroToNumberUnderTen(number) {
    if(number < 10) {
        return "0" + number;
    }
    return number;
}

export {sendRequest, getApiUrl, formatDate}