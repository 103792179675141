import React, { useEffect, useState } from "react";

import ClubBox from "../components/box/clubBox";
import BoxContainer from "../components/box/boxContainer";
import SearchButton from "../components/searchButton";
import { getClubList } from "../api/club/clubManager";
import { ClubCategoryList, CategoryListElement } from "../api/club/clubCategoryList";
import { filterTextSimple } from "../api/searchHelper";

import Loader from "../components/loader";
import EmptyPage from "../components/emptyPage";
import Footer from "../components/footer";

function ClubListPage() {

    const [searchValue, setSearchValue] = useState("");

    const [loading, setLoading] = useState(true);

    const [callParamSearch, setCallParamSearch] = useState(false);
    const [urlSearchParamOld, setUrlSearchParamOld] = useState("oldparam");

    const queryParameters = new URLSearchParams(window.location.search)
    const urlSearchParam = queryParameters.get("search");

    const [categoryList, setCategoryList] = useState([]);

    const [filteredCategoryList, setFilteredCategoryList] = useState([]);

    function isEmpty() {
        var empty = true;
        for(var i = 0; i< categoryList.length; i++) {
            if(categoryList[i].getClubs().length > 0) {
                empty = false;
            }
        }
        return empty;
    }

    useEffect(() => {
        if(categoryList.length == 0) {
            getClubList((data) => {
                setCategoryList(data);
                setFilteredCategoryList(data);
                if(urlSearchParam == null) {
                    setLoading(false);
                }
            });
            setTimeout(() => {
            })
        }
        if(!callParamSearch && urlSearchParam != null) {
            setTimeout(() => {
                updateSearch(urlSearchParam);
                setCallParamSearch(true);
                setLoading(false);
                setUrlSearchParamOld(urlSearchParam);
            }, 1000);
        }
    });

    if(loading) {
        return <Loader/>;
    }

    if(isEmpty()) {
        return <EmptyPage/>;
    }

    function updateSearch(searchValue) {

        setSearchValue(searchValue);

        const onlyCategory = searchValue.startsWith(":k:"), onlyClub = searchValue.startsWith(":v:");
        searchValue = searchValue.replace(":k:", "");
        searchValue = searchValue.replace(":v:", "");

        // Filter content search
        var newList = [];
        for(var i = 0; i < categoryList.length; i++) {
            var category = categoryList[i];
            if(filterTextSimple(category.getName(), searchValue) && !onlyClub) {
                newList.push(category);
            }else if(!onlyCategory){
                var newCategory = new ClubCategoryList(category.getName());
                for(var x = 0; x < category.getClubs().length; x++) {
                    var el = category.getClubs()[x];
                    if(filterTextSimple(el.getName(), searchValue)) {
                        newCategory.addClub(el.getId(), el.getName(), el.getImageLink(), el.isVisible());
                    }
                }
                if(newCategory.isVisible()) {
                    newList.push(newCategory);
                }
            }
        }
        setFilteredCategoryList(newList);
    }

    return <>
        <div className="page">
        <SearchButton defaultValue={urlSearchParam} onInput={(v) => {
            updateSearch(v);
        }}/>
        {filteredCategoryList.map((category, i) => {
            return (
            <div className="section">
                <h1>{category.getName()}</h1>
                <BoxContainer rowElements={4}>
                    {category.getClubs().map((club, i) => {
                        return (
                            <ClubBox name={club.getName()} logo={club.getImageLink()} id={club.getId()}/>
                        );
                    })
                    }
                </BoxContainer>
            </div>
            );
        })}
    </div>
    <Footer/>
    </>;
}

export default ClubListPage;