import { sendRequest } from "./apiManager";

function getText(key, onFinish) {
    sendRequest("text/" + key, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.value);
        }else {
            console.log("Text konnte nicht geladen werden: " + key);
        }
    });
}

export {getText}