import React, {useEffect, useRef, useState} from "react";

import "./multipleSelect.css";

import ChevronIcon from "../assets/icons/chevron";

function MultipleSelect(props) {

    const dropdownEl = useRef();
    const [selected, setSelected] = useState(props.defaultValue ? props.defaultValue : -1);
    const [active, setActive] = useState(false);
    const [defaultItems, setDefaultItems] = useState(props.items);
    const [items, setItems] = useState(props.items);
    const [defaultValue, setDefaultValue] = useState(props.defaultValue);

    useEffect(() => {
        if(defaultItems != props.items) {
            setItems(props.items);
            setDefaultItems(props.items);
        }
        if(defaultValue != props.defaultValue) {
            setDefaultValue(props.defaultValue);
        }
    })

    var added = false;

    useEffect(() => {
        if(defaultValue != undefined && selected == -1) {
            setSelected(defaultValue);
            setItems(props.items);
        }
        dropdownEl.current.scroll(0, 0);
    });

    var clickDelete = false;

    function closeDropdown() {
        setActive(false);
    }

    return <div className={"multiple-select" + (active ? " active" : "")}>
        <p>{props.label}</p>
            <div ref={dropdownEl} className="dropdown" onClick={() => {
                if(clickDelete) return;
                if(active) {
                    closeDropdown();
                }else {
                    setActive(true);
                }
        }}>
            <ChevronIcon className="chevron-down"/> {(selected != -1 ? <p className="active">{props.items[selected]}</p> : active ? <></> : <p className="active empty">{props.placeholder}</p>)}
            {items.map((e, i) => {
                if(i == selected) return;
                return <p onClick={() => {
                    if(clickDelete) return;
                    setSelected(i);
                    if(props.onChange) {
                        props.onChange(i);
                    }
                }}>{e}</p>;
            })}
            {props.editable ? <p className="add" onClick={props.onAdd}>{props.add}</p> : <></>}

            
        </div>
        <div className="overlay" onClick={() => {
            closeDropdown();
        }}/>
    </div>;
}

export default MultipleSelect;