import React, { useEffect, useRef, useState } from "react";

import { getApiUrl, sendRequest } from "../api/apiManager";

function ImageLink(props) {

    const imgRef = useRef();
    useEffect(() => {
        if(props.setImgEl) {
            props.setImgEl(imgRef.current);
        }
    });

    return <img style={props.style ? props.style : {}} alt={props.alt ? props.alt : ""} src={getImageUrl(props.link)} className={props.className ? props.className : ""} ref={imgRef}/>;

}

function getImageUrl(link) {
    return getApiUrl() + "image/" + link;
}

export {getImageUrl};
export default ImageLink;