import React, { useEffect, useState } from "react";

import BoxContainer from "../components/box/boxContainer";
import EventBox from "../components/box/eventBox";

import SearchButton from "../components/searchButton";
import Loader from "../components/loader";

import "./schedule.css";
import BALCalendar from "../components/calendar";
import MultipleBox from "../components/box/multipleBox";
import { getEventsOnDay, getFutureEventCount, getNearestEvents, searchEvents } from "../api/events/eventManager";
import { formatDateFullMonth, getWeekday } from "../utils/dateUtils";
import Footer from "../components/footer";

function SchedulePage() {

    const nearestEventsStepSize = window.innerWidth > 1200 ? 3 : 2;

    const [loading, setLoading] = useState(true);
    const [eventsOnDay, setEventsOnDay] = useState([]);
    const [nearestCount, setNearestCount] = useState(nearestEventsStepSize);
    const [nearestEvents, setNearestEvents] = useState([]);
    const [update, setUpdate] = useState(true);
    const [activeDate, setActiveDate] = useState(new Date());
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [calendarDiv, setCalendarDiv] = useState("");

    useEffect(() => {
        /*window.addEventListener("resize", () => {
            var nearestEventsStepSize = window.innerWidth > 1200 ? 3 : 2;
            console.log(nearestEventsStepSize, nearestCount);
            var mod = nearestCount % nearestEventsStepSize;
            if(mod > 0) {
                console.log("update" + (nearestCount - mod));
                setNearestCount((nearestCount - mod) == 0 ? nearestEventsStepSize : nearestCount - mod);
                setUpdate(true);
            }
        });*/
        if(update) {
            setUpdate(false);
            getNearestEvents((r) => {
                setNearestEvents(r);
                setTimeout(() => {setLoading(false);}, 500);
            }, nearestCount);
            var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            var st = (activeDate.getMonth() + 1) + "." + activeDate.getDate() + "." + (activeDate.getFullYear());
            var start = new Date(st.replace(pattern,'$3-$2-$1'));
            getEventsOnDay((v) => {
                setEventsOnDay(v);
                updateSearch(searchValue);
            }, start.getTime()/1000);
        }
    });

    if(loading) {
        return <Loader/>;
    }

    

    function updateSearch(search) {
        setSearchValue(search);
        if(search.length == 0) {
            setCalendarDiv(undefined);
        }else {
            searchEvents(setSearchResults, search);
            setCalendarDiv(<BoxContainer rowElements={2}>
                {searchResults.map((d) => {
                    return <EventBox event={d}/>;
                })}
            </BoxContainer>);
        }
    }

    return <>
        <div className="page schedule-page">
        {getFutureEventCount() == 0 ? <></> : <div className="section">
            <h1>Nächste Termine</h1>
            <BoxContainer rowElements={3}>
                {nearestEvents.map((d) => {
                    return <EventBox event={d}/>;
                })}
            </BoxContainer>
            {getFutureEventCount() == 0 ? <></> : getFutureEventCount() <= nearestCount ? 
            <a className="expand disabled">weitere Termine anzeigen</a>: 
            <a className="expand" onClick={() => {setNearestCount(nearestCount+nearestEventsStepSize);setUpdate(true);}}>weitere Termine anzeigen</a>}
        </div>}
        <div className="section">
            <h1 className="titleWithSearch">Kalender</h1>
            <SearchButton onInput={(v) => {
                updateSearch(v);
            }}/>
            <div className="calendar-div">
                {calendarDiv == undefined ? <><BALCalendar onChange={(r) => {setActiveDate(r);setUpdate(true);}}/>
            <div className="current-day">
                <h2>{getWeekday(activeDate)}, {formatDateFullMonth(activeDate)}<span>{eventsOnDay.length > 0 ? eventsOnDay.length : "keine"} Termin{eventsOnDay.length === 1 ? "" : "e"}</span></h2>
                {eventsOnDay.length > 0 ? <MultipleBox>
                {eventsOnDay.map((d) => {
                    return <EventBox date={activeDate} event={d}/>;
                })}
                </MultipleBox> : <p className="info">An diesem Tag finden keine Events statt</p>}
            </div></> : calendarDiv}
            </div>
        </div>
    </div>
    <Footer/>
    </>; 
}

export default SchedulePage;