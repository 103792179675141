import React, { useEffect, useRef } from "react";
import RoundInfo from "../roundInfo";

import Box from "./box";
import { useNavigate } from "react-router-dom";

function ArticleBox(props) {

    const navigate = useNavigate();

    const boxEl = useRef(null);
    
    useEffect(() => {
        const maxWidth = window.innerWidth;
    });

    var length = 0;

    return <Box className="hover" title={props.title} date={props.date} img={props.img} onClick={() => {
        window.open("/article/" + props.id, "_self");
    }}>
        {props.tags == undefined || props.tags.length == 0 ? <></>: props.tags.map((r) => {
            length += 60 + r.length*10;
            return <RoundInfo>{r}</RoundInfo>;
        })}
    </Box>;
}

export default ArticleBox;