import React, { useEffect, useState } from "react";
import { getAllArticleList, getNewestArticleList } from "../api/article/articleManager";
import ArticleBox from "../components/box/articleBox";
import BoxContainer from "../components/box/boxContainer";
import Loader from "../components/loader";
import SearchButton from "../components/searchButton";
import { filterTextArraySimple, filterTextSimple } from "../api/searchHelper";
import EmptyPage from "../components/emptyPage";
import Footer from "../components/footer";

function ArticlesPage() {

    const [loading, setLoading] = useState(true);

    const [articleList, setArticleList] = useState([]);
    const [newestList, setNewestList] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    useEffect(() => {
        if(articleList.length == 0) {
            getAllArticleList((r) => {
                setArticleList(r);
                setFilteredArticles(r);
                setTimeout(() => {
                    
                    setLoading(false);
                }, 1000);
            });
            
            getNewestArticleList(setNewestList, 2);
        }
    });

    function updateSearch(searchValue) {
        var newList = [];
        for(var i = 0; i < articleList.length; i++) {
            var article = articleList[i];
            if(filterTextSimple(article.getTitle(), searchValue) || filterTextArraySimple(article.getTags(), searchValue)) {
                newList.push(article);
            }
        }
        setFilteredArticles(newList);
    }

    if(loading) {
        return <Loader/>;
    }

    if(articleList.length == 0) {
        return <EmptyPage/>;
    } 

    return <>
        <div className="page">
        <div className="section">
            <h1>Neuste Beiträge</h1>
            <BoxContainer>
                {newestList.map((r) => {
                    return <ArticleBox id={r.getId()} title={r.getTitle()} date={r.getDateFormat()} tags={r.getTags()} img={r.getImgLink()}/>;
                })}
            </BoxContainer>
        </div>

        <div className="section">
            <h1 className="titleWithSearch">Alle Beiträge</h1>
            <SearchButton onInput={(v) => {
                updateSearch(v);
            }}/>
            <BoxContainer>
                {filteredArticles.map((r) => {
                    return <ArticleBox id={r.getId()} title={r.getTitle()} date={r.getDateFormat()} tags={r.getTags()} img={r.getImgLink()}/>;
                })}
            </BoxContainer>
        </div>
    </div>
    <Footer/>
    </>;
}

export default ArticlesPage;