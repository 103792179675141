import { sendRequest } from "./apiManager";

function getFutureHelperExchanges(onFinish) {
    sendRequest("helper-exchange", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.entries);
        }else {
            console.log(r.message);
        }
    });
}

function getHelperExchange(id, onFinish) {
    sendRequest("helper-exchange/" + id, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.exchange);
        }else {
            console.log(r.message);
        }
    });
}

function getHelperExchangeCategories(onFinish) {
    sendRequest("helper-exchange/categories", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.categories);
        }else {
            console.log(r.message);
        }
    });
}

export {getHelperExchangeCategories, getFutureHelperExchanges, getHelperExchange}