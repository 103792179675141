function filterText(txt, search) {
    if(search.length === 0) return true;
    txt = txt.toLowerCase();
    search = search.toLowerCase();
    var split = search.split(" ");
    for(var i in split) {
        var word = split[i];
        if(word.length == 0) continue;
        if(txt.includes(word)) {
            return true;
        }
    }
    return false;
}

function filterTextSimple(txt, search) {
    if(search.length === 0) return true;
    txt = txt.toLowerCase();
    search = search.toLowerCase();
    if(txt.includes(search)) {
        return true;
    }
    return false;
}

function filterTextArraySimple(arr, search) {
    for(var i = 0; i < arr.length; i++) {
        if(filterTextSimple(arr[i], search)) return true;
    }
    return false;
}

export {filterText, filterTextSimple, filterTextArraySimple}