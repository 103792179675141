import { formatDate } from "../apiManager";

class ArticleBasics {
    constructor(id, title, date, tags, imgLink) {
        this.title = title;
        this.date = date;
        this.tags = tags;
        this.imgLink = imgLink;
        this.id = id;
    }

    getId() {
        return this.id;
    }

    getTitle() {
        return this.title;
    }

    getDate() {
        return this.date;
    }

    getDateFormat() {
        return formatDate(this.date);
    }

    getTags() {
        return this.tags;
    }

    getImgLink() {
        return this.imgLink;
    }
}


export {ArticleBasics}