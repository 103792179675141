import { sendRequest } from "../apiManager";
import { ArticleBasics } from "./articleList";

function getArticle(id, retrieveData) {
    sendRequest("article/" + id, "GET", "", (r) => {
        if(r.status == 200) {
            return retrieveData(r.data);
        }else {
            //TODO: ERROR
        }
    }, "");
}

function getAllArticleList(retrieveData) {
    sendRequest("article", "GET", "", (r) => {
        manageData(r, retrieveData);
    }, "");
}

function getNewestArticleList(retrieveData, number) {
    sendRequest("article/newest" + number, "GET", "", (r) => {
        manageData(r, retrieveData);
    });
}

function manageData(r, retrieveData) {
    var articleList = [];

    for(var i = 0; i < r.data.articles.length; i++) {
        var articleData = r.data.articles[i];
        var tags = [];
        for(var y = 0; y < articleData.tags.length; y++) {
            tags.push(articleData.tags[y].name);
        }
        articleList.push(new ArticleBasics(articleData.id, articleData.title, articleData.time, tags, articleData.titleImg));
    }
    retrieveData(articleList);
}

export {getAllArticleList, getNewestArticleList, getArticle}