import React, { useEffect, useRef, useState } from "react";

import { getFutureEventCount } from "../api/events/eventManager";
import BoxContainer from "../components/box/boxContainer";
import EventBox from "../components/box/eventBox";
import { getNearestEvents } from "../api/events/eventManager";
import { getFutureHelperExchanges } from "../api/aiderManager";

import "./home.css";
import "../style/parallax.css";

import Footer from "../components/footer";
import InfoIcon from "../assets/icons/info";
import EmailIcon from "../assets/icons/email";
import VideoBox from "../components/box/videoBox";
import { getTTFB } from "web-vitals";
import { getText } from "../api/textManager";
import { formatClocktime } from "../utils/dateUtils";
import Loader from "../components/loader";
import { getImageUrl } from "../components/imageLink";
import HelperBox from "../components/box/helperBox";

function HomePage() {

    const background = getImageUrl("-1/2d8a97a4-1e0b-46a6-9335-8f3497eb2a18");

    //Load Textes
    const [startTitle, setStartTitle] = useState("");
    const [startText, setStartText] = useState("");
    const [helperTitle, setHelperTitle] = useState("");
    const [helperText, setHelperText] = useState("");
    const [youtubeTitle, setYoutubeTitle] = useState("");
    const [youtubeText, setYoutubeText] = useState("");
    const [clubTitle, setClubTitle] = useState("");
    const [clubText, setClubText] = useState("");

    const firstExecute = useRef(false);
    const [nearestEvents, setNearestEvents] = useState([]);

    const [aider, setAider] = useState([]);

    useEffect(() => {
        if(firstExecute.current) return;
        getNearestEvents((r) => {
            setNearestEvents(r);
        }, 3);
        getFutureHelperExchanges((d) => {
            var output = [];
            for(var i = 0; i < d.length; i++) {
                var el = d[i];
                output.push({
                    "id": el.id,
                    "title": el.title,
                    "nextStartTime": el.startTime,
                    "time": formatClocktime(new Date(el.startTime), new Date(el.endTime)),
                    "location": el.location,
                    "club": el.creator.username,
                    "category": el.category
                });
            }
            setAider(output);
        });
        getText("start_about_title", setStartTitle);
        getText("start_about_text", setStartText);
        getText("start_helper_text", setHelperText);
        getText("start_helper_title", setHelperTitle);
        getText("start_youtube_title", setYoutubeTitle);
        getText("start_youtube_text", setYoutubeText);
        getText("start_club_title", setClubTitle);
        getText("start_club_text", setClubText);
        firstExecute.current = true;
    });

    if(!startTitle) {
        return <Loader/>;

    }

    return <>
        <div id="home-page" className="page">
        <div className="title-img img-banner" style={{
            backgroundImage: "url(" + background + ")"
        }}/>
        <div className="overlap-box about-box">
            <h1>{startTitle}</h1>
            {startText.split("\n").map(e => {
                return <p>{e}</p>
            })}
        </div>
        <div className="section">
            <h1>Nächste Termine</h1>
            
            {getFutureEventCount() == 0 ? <h3 className="no-content">Aktuell gibt es keine neuen Termine</h3> : <BoxContainer rowElements={3}>
                {nearestEvents.map((d) => {
                    return <EventBox event={d}/>;
                })}
            </BoxContainer>}
            
        </div>
        <div className="overlap-box help-box">
            <h1>{helperTitle}</h1>
            <p>{helperText}</p>
            <div className="search-button">
                <EmailIcon/>
                <a href="/aider">Helferbörse abonnieren</a>
            </div>
        </div>
        <div className="help-banner img-banner" style={{
            backgroundImage: "url(" + background + ")"
        }}/>
        <div className="section white">
            <h1 className="white">nächste Hilfegesuche</h1>
            
            {aider.length > 0 ? <BoxContainer rowElements={3}>
                {aider.map((d, i) => {
                    if(i > 2) return;
                    return <HelperBox aider={d}/>;
                })}
            </BoxContainer> : <h3 className="no-content big-spacing">Momentan werden keine Helfer benötigt</h3>}
        </div>
        <div className="section culture">
            <VideoBox title="aktuelles Video"/>
            <h1>{youtubeTitle}</h1>
            <p>{youtubeText}</p>
            
        </div>
        <div className="section club-ad">
            <h1>{clubTitle}</h1>
            <p>{clubText}</p>
            <div className="search-button" onClick={() => {
                window.open("mailto:info@bergamlaimer.info", "_self");
            }}>
                <EmailIcon/>
                <a>als Verein registrieren</a>
            </div>
        </div>
    </div>
    <Footer/>
    </>;
}

export default HomePage;