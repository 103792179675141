import React from "react";
import SearchIcon from "../assets/icons/search";

import "./searchInput.css";

function SearchInput(props) {
    return <div className="search-input" onChange={props.onChange}>
        <input placeholder={props.placeholder}></input>
        <SearchIcon/>
    </div>
}

export default SearchInput;