import React from "react";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../../api/apiManager";

import logo from "../../assets/img/logo1.webp";

export default function ClubBox(props) {

    var logoLink = getApiUrl() + "image/" + props.logo;

    const navigate = useNavigate();

    return <div className="club-box hover" onClick={() => {window.open("/club/" + props.id, "_self")}}>
        <div className={"box " + props.className}>
            <div className="logo" style={{
                "backgroundImage": "url(" + logoLink + ")"
            }}>
            </div>
        </div>
        <h3>{props.name}</h3>
    </div>;
}